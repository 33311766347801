import { HttpHeader } from '@solidjs/start';
import { createEffect, createMemo, createSignal, For, Match, Show, Switch, useContext } from 'solid-js';
import { ActivityIndicator, Button, Link, TextLink } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, useLocation, useParams } from '@solidjs/router';
import { IconCheck, IconCircleCheck, IconSquareWarning } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Title } from '@solidjs/meta';
import { Content } from '../../../components/content';
import { useUser } from '../../../providers/user';
import { logout } from '../../auth/components/logout-action';
import { AuthFlow, useAuthStore } from '../../../partials/auth/auth';
import { getApiClient, gql, StripeClientSecretIntentType } from '../../../graphql';
import { Grid, GridThird, GridTwoThirds } from '../../../components/layouts/grid';
import { AccessProductsCtx } from '../../../providers/card';
import { useStripe } from '../../../providers/stripe';
import { getBaseUrl } from '../../../modules/schema/base-url';
import { SupportButton } from '../../../components/support';
import { Elements } from './_components/elements';
import { Receipt } from './_components/receipt';
import { ProductInfo } from './_components/product-info';
import type { TroonCardSubscriptionIntent } from '../../../graphql';
import type { FlowStep } from '../../../partials/auth/flow';
import type { JSX } from 'solid-js';
import type { StripeError } from '@stripe/stripe-js';
import type { GraphQLError } from 'graphql';

const maxAttempts = 3;

export default function CardCheckout() {
	const params = useParams<{ id: string }>();
	const location = useLocation<{ redirect?: string }>();
	const user = useUser();
	const products = useContext(AccessProductsCtx);
	const trackEvent = useTrackEvent();
	const handleLogout = logout(trackEvent, '/card/checkout');
	const [intentErrors, setIntentErrors] = createSignal<Array<GraphQLError> | undefined>();
	const [errors, setErrors] = createSignal<StripeError | undefined>();
	const [submitting, setSubmitting] = createSignal(false);

	const [subscription, setSubscription] = createSignal<TroonCardSubscriptionIntent>();

	const { elements, setAmount, stripe } = useStripe();

	const productInfo = createMemo(() => {
		return products()!.find((card) => card.id === params.id)!;
	});

	createEffect(() => {
		const total = subscription()?.totalAmount;
		if (total) {
			setAmount(total);
		}
	});

	createAsync(async () => {
		if (!user()) {
			setSubscription(undefined);
			return;
		}

		if (subscription()) {
			return;
		}

		const productId = params.id;

		if (!productId) {
			return;
		}

		// Manual retry logic. NB: `mutationAction` has this built in
		let intent: TroonCardSubscriptionIntent | undefined;
		for (let i = 0; i < maxAttempts; i += 1) {
			const data = await getApiClient().mutation(query, { productId }, {});
			if (data.data) {
				intent = data.data.cardSubscription as unknown as TroonCardSubscriptionIntent;
			}
			if (!data.error) {
				setIntentErrors(undefined);
				break;
			}
			if (
				data.error.graphQLErrors?.find((e) => {
					return typeof e.message === 'string' && e.message.includes('not eligible');
				})
			) {
				setIntentErrors(data.error.graphQLErrors);
			}
			await new Promise<void>((resolve) => {
				setTimeout(() => {
					resolve();
				}, 500);
			});
		}

		setSubscription(intent);
	});

	return (
		<Show
			when={productInfo()}
			fallback={
				<Content>
					<Title>404 not found | Troon</Title>
					<HttpHeader name="status" value="404" />
					<h1 class="my-16 text-6xl font-semibold text-brand">Not Found</h1>

					<div class="flex flex-row">
						<div>
							<Button as={Link} href="/">
								Return home
							</Button>
						</div>
					</div>
				</Content>
			}
		>
			<div class="-mt-8 mb-12 md:-mt-16 lg:hidden">
				<ProductInfo {...productInfo()} />
			</div>
			<Content>
				<Grid class="lg:gap-12 xl:gap-20">
					<GridTwoThirds class="flex flex-col gap-12">
						<h1 class="sr-only text-4xl font-semibold md:not-sr-only">Complete purchase</h1>

						<section>
							<Show
								when={user()}
								fallback={
									<div class="flex flex-col gap-4">
										<div class="flex flex-row gap-4">
											<div
												role="presentation"
												class="flex size-10 shrink items-center justify-center rounded-full bg-brand-100 text-xl font-semibold text-brand"
											>
												1
											</div>
											<h2 class="text-xl font-semibold leading-10">Log in or sign up</h2>
										</div>
										<AuthFlow inline headers={authHeaders} showSteps={false} />
									</div>
								}
							>
								{(user) => (
									<div class="flex flex-row gap-4">
										<div class="flex size-10 shrink items-center justify-center rounded-full bg-brand-100 text-brand">
											<IconCheck class="size-6" />
										</div>
										<div class="flex grow flex-wrap justify-between">
											<div class="min-w-64 grow">
												<h2 class="text-xl font-semibold leading-10">Account information</h2>
												<ul class="flex flex-col gap-1">
													<li>
														{user().me.firstName} {user().me.lastName}
													</li>
													<li>{user().me.email}</li>
													<li>{user().me.troonRewardsId}</li>
												</ul>
											</div>

											<div>
												<TextLink
													href="/auth/logout"
													onClick={(e) => {
														e.preventDefault();
														handleLogout();
													}}
												>
													Log out
												</TextLink>
											</div>
										</div>
									</div>
								)}
							</Show>
						</section>

						<section class="flex flex-col gap-4">
							<div class="flex flex-row gap-4">
								<div
									role="presentation"
									class={twJoin(
										'flex size-10 shrink items-center justify-center rounded-full text-xl font-semibold ',
										user() ? 'bg-brand-100 text-brand' : 'bg-neutral-300 text-neutral-700',
									)}
								>
									2
								</div>
								<h2 class="text-xl font-semibold leading-10">Payment information</h2>
							</div>
							<Switch>
								<Match when={intentErrors()}>
									<p class="flex items-baseline gap-2 ps-14 font-semibold text-red-500">
										<IconSquareWarning class="relative top-1 shrink-0" />{' '}
										<span class="">
											A problem occurred when attempting to set up this purchase. Please try again. If this problem
											persists, please{' '}
											<SupportButton
												appearance="transparent-current"
												class="inline w-fit shrink grow-0 p-1 normal-case underline"
											>
												contact support
											</SupportButton>
											.
										</span>
									</p>
								</Match>
								<Match when={user()}>
									<Elements />
								</Match>
							</Switch>
						</section>
					</GridTwoThirds>

					<GridThird>
						<div class="overflow-y-hidden border-neutral lg:rounded lg:border">
							<div class="hidden overflow-hidden lg:block">
								<ProductInfo {...productInfo()} />
							</div>

							<Show when={user()}>
								<div data-testId="receipt" class="flex flex-col gap-6 py-6 lg:p-6">
									<Switch fallback={<ActivityIndicator />}>
										<Match when={intentErrors()}>
											{(errors) => (
												<For each={errors()}>
													{(error) => (
														<p class="text-red-500" aria-live="assertive">
															{error.message}
														</p>
													)}
												</For>
											)}
										</Match>
										<Match when={subscription()}>
											{(subscription) => (
												<Receipt
													subscription={subscription()}
													onReceivePromoSubscription={(newSub) => setSubscription(newSub)}
													productId={params.id}
												/>
											)}
										</Match>
									</Switch>

									<Button
										type="button"
										disabled={!!intentErrors()?.length}
										onClick={async (event) => {
											event.preventDefault();

											trackEvent('checkoutRequest', { productId: params.id });
											const { error: submitError } = await elements()!.submit();
											if (submitError) {
												trackEvent('checkoutFailure', { productId: params.id });
												setErrors(submitError);
												return;
											}

											const redirect = location.state?.redirect ?? '/access/checkout/confirm';

											const returnUrl = new URL(redirect, getBaseUrl());
											returnUrl.searchParams.set('subscriptionId', subscription()!.troonCardSubscriptionId);
											returnUrl.searchParams.set('productId', params.id ?? '');

											setSubmitting(true);

											let error: StripeError | undefined;

											if (subscription()!.stripeClientSecretIntentType === StripeClientSecretIntentType.Setup) {
												const res = await stripe()!.confirmSetup({
													elements: elements()!,
													clientSecret: subscription()!.stripeClientSecret,
													confirmParams: {
														return_url: returnUrl.toString(),
													},
												});
												error = res.error;
											} else {
												const res = await stripe()!.confirmPayment({
													elements: elements()!,
													clientSecret: subscription()!.stripeClientSecret,
													confirmParams: {
														return_url: returnUrl.toString(),
													},
												});
												error = res.error;
											}

											setSubmitting(false);

											if (error) {
												trackEvent('checkoutFailure', { productId: params.id });
												setErrors(error);
											} else {
												setErrors();
											}
										}}
									>
										<Show when={submitting()}>
											<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
										</Show>
										Subscribe & pay
									</Button>
									<Show when={errors()}>
										{(error) => (
											<p class="text-red-500" aria-live="assertive">
												There was a problem completing the request: {error().message}
											</p>
										)}
									</Show>

									<p class="text-center text-sm">
										By clicking “Subscribe & pay”, you are agreeing to Troon’s{' '}
										<TextLink target="_blank" href="https://www.troon.com/terms-of-use/">
											Terms of Service
										</TextLink>{' '}
										and{' '}
										<TextLink target="_blank" href="https://www.troon.com/privacy-policy/">
											Privacy Policy
										</TextLink>
										.
									</p>
								</div>
							</Show>
						</div>
					</GridThird>
				</Grid>
			</Content>
		</Show>
	);
}

const query = gql(`mutation setupTroonCardSubscription($productId: String!) {
  cardSubscription: setupTroonCardSubscription(troonCardSubscriptionProductId: $productId) {
    ...Subscription
  }
}
fragment Subscription on TroonCardSubscriptionIntent {
  stripeClientSecret
  stripeCustomerId
  subscriptionName
  troonCardSubscriptionId
  stripeClientSecretIntentType
  totalAmount {
    ...Currency
  }
  subtotalAmount {
    ...Currency
  }
  discountAmount {
    ...Currency
  }
  taxAmount {
    ...Currency
  }
}`);

const authHeaders: Record<FlowStep, () => JSX.Element> = {
	'/auth': () => (
		<p>
			Enter the email associated with your Troon Card/Troon Rewards Account to log in. If you don’t have one, you can
			create a new account for free.
		</p>
	),
	'/auth/login': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to login and
					continue.
				</p>
			</>
		);
	},
	'/auth/join': () => {
		return (
			<>
				<FoundUser />
				<p>Complete the following account information to set up your Troon Account.</p>
			</>
		);
	},
	'/auth/reset': () => (
		<>
			<FoundUser />
			<p>Check your email for a confirmation code.</p>
		</>
	),
	'/auth/reset/password': () => (
		<>
			<FoundUser />
			<p>Create a password with at least 8 letters and numbers.</p>
		</>
	),
	'/auth/magic-link': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We sent a login code to <b>{store.data.email}</b>. Copy the code and enter it below to continue logging in.
				</p>
			</>
		);
	},
};

function FoundUser() {
	const [store] = useAuthStore();
	return (
		<p class="flex flex-row items-center gap-2 rounded border border-neutral-300 bg-neutral-100 px-4 py-3 text-neutral-800">
			<IconCircleCheck class="size-6 text-green-500" />
			{store.data.email}
		</p>
	);
}
